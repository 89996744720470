@import '__importable.scss';
// @sarachai is a god for this
.widget {
	:global {
		// Yotpo Widget Loading Behavior
		.yotpo-widget-instance {
			animation: pulse 1.5s ease-out;
			animation-iteration-count: infinite;
			-webkit-animation: pulse 1.5s ease-out;
			-webkit-animation-iteration-count: infinite;
			opacity: 0;

			// Campaigns + VIP Tiers
			&[data-yotpo-instance-id='190231'],
			&[data-yotpo-instance-id='190232'] {
				min-height: 420px;
				background: white;
				border-radius: 8px;
			}

			@-webkit-keyframes pulse {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
		}

		// Campaigns full-bleed background color
		.yotpo-widget-instance-container[yotpo-instance-id='190231'] {
			background: $gray-1;
		}

		// Yotpo Widget Custom CSS
		.yotpo-widget-override-css {
			&.yotpo-widget-hero-section {
				.yotpo-text-on-background {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					.yotpo-text-container {
						top: auto;

						.yotpo-title {
							padding-bottom: 8px;
							font-size: 4rem !important;
						}

						.yotpo-description {
							font-size: 1.6rem !important;
							font-weight: 300;
							line-height: 2.4rem;
						}
					}

					&.yotpo-is-mobile {
						position: relative;
						display: block;
						min-height: 512px;

						.yotpo-background-text-mobile {
							width: 100%;
							height: 512px;
							padding: 0;
							margin: 0;
						}

						.yotpo-text-and-buttons {
							position: absolute;
							top: 0;
							max-width: unset;
							padding: 2.4rem;

							.yotpo-customer-logged-out-buttons {
								display: flex;
								gap: 0.8rem;
							}
						}
					}
				}
			}

			&.yotpo-widget-my-rewards-widget {
				:focus {
					box-shadow: none;
				}

				.yotpo-tile-box {
					padding: 4.8rem 0;
				}

				.yotpo-headline .yotpo-inner-text {
					font-size: 3.2rem !important;
					line-height: 3.8rem !important;
					letter-spacing: 0.0032em;
				}

				.yotpo-step-icon {
					height: 100px !important;
					margin-bottom: 3.2rem;
				}

				.yotpo-reward-steps-title .yotpo-inner-text {
					margin-bottom: 0.8rem;
					font-size: 2rem !important;
					line-height: 2rem !important;
				}

				.yotpo-reward-steps-description .yotpo-inner-text {
					font-size: 1.4rem !important;
					line-height: 2.1rem !important;
				}

				.yotpo-is-mobile .yotpo-info {
					margin-bottom: 0;
				}

				.yotpo-logged-in-view {
					.yotpo-description {
						.yotpo-inner-text {
							font-family: var(--poppins) !important;
							font-size: 1.4rem !important;
							font-weight: normal !important;
							line-height: 2.1rem !important;
						}
					}

					.yotpo-primary-button-link {
						display: none !important;
					}

					.yotpo-secondary-button {
						padding-left: 0 !important;
					}
				}

				// Rewards History Modal (Desktop)
				.yotpo-background:not(.yotpo-is-mobile) {
					.yotpo-loyalty-popup-overlay {
						background: rgb(0 0 0 / 10%) !important;

						.yotpo-close-button {
							position: absolute;
							top: auto;
							margin-top: 32px;
							right: 64px;
							z-index: 100;
							height: 20px;
							display: flex;
							align-items: center;

							&:before {
								content: 'Close';
								color: rgba(8, 5, 147, 1);
								font-family: var(--poppins);
								font-size: 1.4rem;
								font-weight: 500;
								line-height: 2.1rem;
							}

							svg {
								display: none;
							}
						}

						.yotpo-rewards-history-wrapper {
							.yotpo-rewards-history {
								padding: 32px 64px;
								filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.25));
								border-radius: 16px;

								.yotpo-rewards-history-tile {
									.yotpo-rewards-history-title {
										width: auto !important;
										margin: 0 0 16px 0;

										.yotpo-inner-text {
											font-size: 2rem !important;
											font-weight: normal !important;
											line-height: 2rem !important;
										}
									}

									.yotpo-grid-wrapper {
										@mixin rowStyle() {
											width: 100%;
											height: 70px;
											background: transparent !important;
											border-bottom: 1px solid #efedee !important;
										}

										.yotpo-grid-row-headers {
											margin: 0;

											.yotpo-row-display {
												@include rowStyle();

												.yotpo-customized-text {
													padding: 0 !important;
												}

												.yotpo-inner-text {
													color: black !important;
													font-family: var(--platform);
													font-size: 1.8rem !important;
													font-weight: normal !important;
													line-height: 2.2rem !important;
												}
											}
										}

										.yotpo-shadow-gap-desktop {
											display: none;
										}

										.yotpo-grid {
											.yotpo-grid-row {
												margin: 0;

												.yotpo-row-display {
													@include rowStyle();

													.yotpo-customized-text {
														padding: 0 !important;
													}

													.yotpo-inner-text {
														color: black !important;
														font-family: var(--poppins);
														font-size: 1.4rem !important;
														font-weight: normal !important;
														line-height: 2.1rem !important;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				// Rewards History Modal (Mobile)
				.yotpo-is-mobile {
					.yotpo-loyalty-popup-overlay {
						top: $headerHeight;

						.yotpo-close-button {
							position: fixed;
							top: auto;
							margin-top: 32px;
							right: 16px;
							z-index: 100;
							height: 29px;
							display: flex;
							align-items: center;

							&:before {
								content: 'Close';
								color: rgba(8, 5, 147, 1);
								font-family: var(--poppins);
								font-size: 1.4rem;
								font-weight: 500;
								line-height: 2.1rem;
							}

							svg {
								display: none;
							}
						}

						.yotpo-rewards-history-wrapper {
							.yotpo-rewards-history {
								padding: 32px 16px;

								.yotpo-rewards-history-tile {
									.yotpo-rewards-history-title {
										width: auto !important;
										margin: 0 0 16px 0;
										padding: 0;

										.yotpo-inner-text {
											font-size: 2.4rem !important;
											font-weight: normal !important;
											line-height: 2.9rem !important;
										}
									}

									.yotpo-grid-wrapper {
										width: 100%;

										@mixin rowStyle() {
											width: 100%;
											height: 70px;
											padding: 0;
											background: transparent !important;
											border-bottom: 1px solid #efedee !important;
										}

										.yotpo-shadow-gap {
											display: none;
										}

										.yotpo-grid {
											.yotpo-grid-row {
												margin: 0;

												.yotpo-row-display {
													@include rowStyle();
													display: flex;
													flex-direction: column;
													justify-content: center;

													.yotpo-inner-text {
														color: black !important;
														font-family: var(--poppins);
														font-size: 1.4rem !important;
														font-weight: normal;
														line-height: 2.1rem !important;
													}

													.row-action-column {
														.yotpo-inner-text {
															font-weight: 500 !important;
														}
													}

													.row-date-column,
													.row-status-column {
														.yotpo-inner-text {
															color: #666163 !important;
														}
													}

													.row-points-column {
														.yotpo-inner-text {
															display: inline;
															padding: 2px 5px;
															color: white !important;
															background: #3431dc;
															border-radius: 4px;
															font-family: var(--platform) !important;
															letter-spacing: 0.02rem;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			&.yotpo-widget-campaign-widget {
				:focus {
					box-shadow: none;
				}

				.yotpo-widget-campaign-widget-container {
					padding: 48px 0;
				}

				// Heading (Desktop)
				.yotpo-container-headline {
					padding-bottom: 44px;
					font-size: 3.6rem !important;
					font-weight: normal !important;
					line-height: 4.3rem !important;
					letter-spacing: 0.0036em;
				}

				// Heading (Mobile)
				.yotpo-is-mobile .yotpo-container-headline:after {
					content: 'Tap on the reward to learn more';
					display: block;
					margin-top: 2px;
					color: #666163;
					font-family: var(--poppins);
					font-size: 1.4rem;
					line-height: 2.1rem;
				}

				.flexified-child {
					height: 192px;
					border-radius: 8px;
					overflow: hidden;
				}

				// Tile (Desktop)
				.yotpo-tile {
					justify-content: space-between;
					padding: 32px 16px;
					cursor: default;

					.yotpo-widget-campaign-widget-icon-container {
						margin-bottom: auto;
						display: flex;
						justify-content: center;
					}

					.yotpo-title-text {
						margin-top: 10px;
						font-size: 2rem !important;
						font-weight: normal !important;
						line-height: 2rem !important;
					}

					.yotpo-description-text {
						align-self: center;
						margin-top: 10px;
						padding: 2px 5px;
						border-radius: 4px;
						font-family: var(--platform) !important;
						font-size: 1.4rem !important;
						font-weight: normal !important;
						letter-spacing: 0.02rem;
					}

					// Lock Icon
					&.yotpo-campaign-tile-createaccountcampaign,
					&.yotpo-campaign-tile-instagramfollowcampaign,
					&.yotpo-campaign-tile-facebookpagevisitcampaign,
					&.yotpo-campaign-tile-facebooksharecampaign {
						&:before {
							content: '';
							position: absolute;
							top: 16px;
							left: 16px;
							height: 24px;
							width: 24px;
							background: url(https://cdn.shopify.com/s/files/1/1147/9910/files/yotpo_campaigns_lock_icon_9743c887-444a-48f9-a9a8-98b913a66b75.png?v=1654895078)
								no-repeat;
						}
					}

					// Check Icon
					.yotpo-completed-icon {
						position: absolute;
						top: 16px;
						left: 16px;
						height: 24px !important;
						width: 24px !important;
						padding: 0 !important;
						background: url(https://cdn.shopify.com/s/files/1/1147/9910/files/yotpo_campaigns_check_icon_90d4def9-4bfd-470a-a3f2-2afe8acb1af4.png?v=1655854886)
							no-repeat;

						svg {
							display: none;
						}
					}

					&.yotpo-tile-completed {
						// Because Yotpo replaces the campaign name with "Completed", we must add it back using pseudo-selectors.
						&.yotpo-campaign-tile-createaccountcampaign .yotpo-title-text,
						&.yotpo-campaign-tile-instagramfollowcampaign .yotpo-title-text,
						&.yotpo-campaign-tile-facebookpagevisitcampaign .yotpo-title-text,
						&.yotpo-campaign-tile-facebooksharecampaign .yotpo-title-text {
							color: transparent !important;
							font-size: 0rem !important;
							line-height: 0rem !important;
						}

						.yotpo-title-text:before {
							color: #000;
							font-size: 2rem;
							font-weight: normal;
							line-height: 2rem;
						}

						&.yotpo-campaign-tile-createaccountcampaign .yotpo-title-text:before {
							content: 'Create an Account';
						}

						&.yotpo-campaign-tile-instagramfollowcampaign .yotpo-title-text:before {
							content: 'Follow us on Instagram';
						}

						&.yotpo-campaign-tile-facebookpagevisitcampaign .yotpo-title-text:before {
							content: 'Follow our Facebook Page';
						}

						&.yotpo-campaign-tile-facebooksharecampaign .yotpo-title-text:before {
							content: 'Share on Facebook';
						}
					}
				}

				// Tile (Mobile)
				.yotpo-is-mobile {
					.yotpo-tile {
						padding: 24px 16px;

						.yotpo-widget-campaign-widget-icon-container {
							height: 58px !important;
						}
					}
				}

				// Overlay (Desktop)
				.yotpo-overlay-tile {
					cursor: pointer;

					.yotpo-message-tile,
					.yotpo-container-action-tile {
						border: 1px solid #3431dc;
						border-radius: 8px;
						box-shadow: inset 2px -2px 0px #3431dc;
						background: rgba(255, 255, 255, 0.85) !important;
					}

					// Informational Overlay
					.yotpo-message-tile {
						.yotpo-message-tile-text {
							max-width: unset;
							padding: 16px 24px;
							font-family: var(--poppins) !important;
							font-size: 1.6rem !important;
							font-weight: normal !important;
							line-height: 2.4rem !important;
						}
					}

					// Action Overlay (has a call-to-action)
					.yotpo-action-tile {
						justify-content: space-evenly;
						width: 100%;
						padding: 16px 24px;

						.yotpo-title-text {
							font-family: var(--poppins) !important;
							font-size: 1.6rem !important;
							font-weight: normal !important;
							line-height: 2.4rem !important;
						}

						.yotpo-widget-date-picker {
							margin: 10px auto;
						}

						.yotpo-widget-mmddyyyy {
							width: 100%;
							padding: 10px;
							border-color: #9b9a9a;
							border-radius: 4px;

							> div {
								display: flex;
							}

							.yotpo-widget-number-input {
								color: #9b9a9a;
								font-family: var(--poppins);
								font-size: 1.6rem;
								font-weight: normal !important;
								line-height: 2.4rem;
							}

							.yotpo-widget-two-digit-input,
							.yotpo-widget-four-digit-input {
								width: auto;
							}

							.yotpo-widget-field-separator {
								padding: 0;
								color: #d6d6d6;
							}
						}

						.yotpo-button-style {
							height: auto;
							margin: 0;
							padding: 10px 0;
							font-family: var(--poppins) !important;
							font-size: 1.4rem !important;
							font-weight: normal !important;
							line-height: 2.1rem !important;

							&:hover {
								background: #00ae4f !important;
								border: 1px solid #00853c !important;
								box-shadow: inset 2px -2px 0px #037436 !important;
							}

							&:active {
								background: #037436 !important;
								border: none !important;
								box-shadow: none !important;
							}
						}
					}

					&.yotpo-container-birthday-action-tile .yotpo-message-text {
						display: none;
					}
				}

				// Overlay (Mobile)
				.yotpo-is-mobile {
					// Completed overlay modals don't actually display properly.
					.yotpo-completed-tile.yotpo-overlay-tile {
						display: none;
					}

					.yotpo-logged-out-tile,
					.yotpo-message-tile,
					.yotpo-container-action-tile {
						position: fixed;
						width: calc(100% - 32px);
						height: 480px;
						top: 50%;
						margin: -240px 16px 0 16px;
						padding: 0 !important;
						background: white !important;
						border: none;
						border-radius: 16px;
						box-shadow: 0px 8px 8px rgb(0 0 0 / 12%), 0 0 500px 500px rgb(0 0 0 / 10%);

						.yotpo-widget-campaign-widget-close-button,
						.yotpo-close-button {
							position: absolute;
							top: 24px;
							right: 24px;
							margin: 0;
						}
					}

					// For some god-forsaken reason, for SOME tiles, this modal close button appears both outside AND inside of
					// the modal. In this scenario only the button OUTSIDE of the tile will work to close the modal. If it's
					// outside, we fixed-position it on top of the dead button that is inside of the tile (smh). If it's inside
					// (as above) we simply absolute-position it within the tile. ;_;
					// Also if you're logged out, it has a different class name. ;_;
					.yotpo-widget-campaign-widget-close-button,
					.yotpo-close-button {
						position: fixed;
						top: 50%;
						margin-top: calc(24px - 240px);
						right: calc(16px + 24px);
						z-index: 100;
						background: white;

						&:before {
							content: 'Close';
							color: rgba(8, 5, 147, 1);
							font-family: var(--poppins);
							font-size: 1.4rem;
							font-weight: 500;
							line-height: 2.1rem;
						}

						svg {
							display: none;
						}
					}

					.yotpo-action-tile {
						justify-content: center;

						.yotpo-widget-date-picker {
							margin: 14px auto;
							max-width: unset;
						}

						.yotpo-title-text + .yotpo-action-button-widget {
							margin-top: 14px;
						}

						.yotpo-button-style {
							max-width: unset;
						}
					}
				}
			}

			&.yotpo-widget-visual-redemption-widget {
				.yotpo-background {

					.yotpo-tile-box {
						padding: 80px 0;

						.yotpo-info {
							margin: 0 auto;

							.yotpo-headline {
								font-size: 3.6rem !important;
								font-weight: 500;
								line-height: 4.3rem !important;
								letter-spacing: 0.0036em;
							}

							.yotpo-description {
								max-width: 428px;
								margin: 0 auto;
								font-size: 1.6rem !important;
								font-weight: 300;
								line-height: 2.4rem !important;
								letter-spacing: 0em;
							}

							.yotpo-rule {
								display: none;
							}
						}
					}
				}
			}

			&.yotpo-vip-tiers-widget {
				.yotpo-vip-tiers-progress-bar-tier-status,
				.yotpo-progress-bar-top-left,
				.yotpo-progress-bar-top-right,
				.yotpo-progress-bar-bottom-right {
					display: none;
				}

				.yotpo-vip-tiers-list {
					padding: 48px 0;
				}

				// Heading
				.yotpo-vip-tiers-headline {
					margin-bottom: 48px;
					font-size: 3.6rem !important;
					font-weight: 500;
					line-height: 4.3rem !important;
					letter-spacing: 0.0036em;
				}

				.yotpo-is-mobile .yotpo-vip-tiers-headline {
					margin-bottom: 16px;
				}

				// Progress Bar (Desktop)
				.yotpo-vip-tiers-progress-bar-wrapper {
					display: none;
				}

				// Progress Bar (Mobile)
				.yotpo-is-mobile {
					.yotpo-vip-tiers-progress-bar-wrapper {
						display: none;
					}
				}

				// Tier Cards
				@mixin tierCardStyling() {
					.yotpo-vip-tier-container {
						position: relative;
						height: 360px;
						border-radius: 8px;
						overflow: hidden;
						z-index: 0;
						// Lock Icon
						&:before {
							content: '';
							position: absolute;
							top: 16px;
							left: 16px;
							height: 24px;
							width: 24px;
							background: url(https://cdn.shopify.com/s/files/1/1147/9910/files/yotpo_campaigns_lock_icon_9743c887-444a-48f9-a9a8-98b913a66b75.png?v=1654895078)
								no-repeat;
							z-index: 100;
						}

						// Check Icon
						&.yotpo-has-status-tag:before {
							background: url(https://cdn.shopify.com/s/files/1/1147/9910/files/yotpo_campaigns_check_icon_90d4def9-4bfd-470a-a3f2-2afe8acb1af4.png?v=1655854886)
								no-repeat;
						}

						.yotpo-vip-current-status {
							display: none !important;
						}

						// Newbie
						&:nth-of-type(1) {
							.yotpo-vip-tier {
								background: #ffd8e0 !important;
							}
						}

						// Regular
						&:nth-of-type(2) {
							.yotpo-vip-tier {
								background: #b5def4 !important;
							}
						}

						// VIP
						&:nth-of-type(3) {
							.yotpo-vip-tier {
								background: #ffec56 !important;
							}
						}

						.yotpo-vip-tier {
							position: relative;
							height: 100% !important;
							padding: 24px 16px 16px;

							.yotpo-vip-tier-header {
								margin: 8px 0 0 0;
								padding: 0;

								.yotpo-vip-tier-icon-wrapper {
									height: 100px;
								}

								.yotpo-vip-tier-name {
									text-align: left;
									font-size: 2.4rem !important;
									font-weight: normal !important;
									line-height: 2.9rem !important;
								}

								// Display this at the bottom of the container
								.yotpo-vip-tier-threshold {
									position: absolute;
									bottom: 16px;
									width: calc(100% - 32px);
									height: auto !important;
									padding-top: 8px;
									border-top: 1px solid black;
									font-size: 1.4rem !important;
									font-weight: normal !important;
									line-height: 1.7rem !important;
									letter-spacing: 0.02em;
									text-align: left;
								}
							}

							.yotpo-vip-tier-benefits {
								margin: 10px 0 0 0;
								padding: 0;

								&:before {
									content: 'Rewards:';
									margin-bottom: 8px;
									font-family: var(--platform);
									font-size: 1.4rem;
									font-weight: normal;
									line-height: 1.7rem;
								}

								.yotpo-vip-tier-benefits-list {
									margin: 0;

									.yotpo-vip-tier-benefits-list-item {
										margin: 0;

										&:before {
											content: '\2022';
											margin-right: 8px;
										}

										svg {
											display: none;
										}

										.yotpo-vip-tier-benefit-text {
											font-size: 1.4rem !important;
											font-weight: normal !important;
											line-height: 2.1rem !important;
										}
									}
								}
							}
						}
					}
				}

				@mixin tierColors() {
					// Newbie
					&:nth-of-type(1) {
						.yotpo-vip-tier {
							background: #ffd8e0 !important;
						}
					}

					// Regular
					&:nth-of-type(2) {
						.yotpo-vip-tier {
							background: #b5def4 !important;
						}
					}

					// VIP
					&:nth-of-type(3) {
						.yotpo-vip-tier {
							background: #ffec56 !important;
						}
					}
				}

				// Tier Cards (Desktop)
				.yotpo-vip-tiers-wrapper {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					gap: 1.6rem;
					width: 100%;

					@include tierCardStyling();

					.yotpo-vip-tier-container {
						width: auto;
						margin: 0;

						@include tierColors();
					}

					@media screen and (width < $media-md) {
						grid-template-columns: 1fr;
					}
				}

				// Tier Cards (Mobile)
				.yotpo-vip-tiers-mobile-wrapper {
					@include tierCardStyling();

					.VueCarousel-slide {
						@include tierColors();
					}

					.yotpo-vip-tier {
						.yotpo-vip-tier-benefits {
							max-height: unset;
							min-height: unset;
						}
					}
				}
			}

			&.yotpo-widget-referral-widget {
				@media screen and (min-width: $media-sm) {
					max-height: 670px;
					overflow: hidden;
				}

				max-height: unset !important;

				.yotpo-background {
					&.yotpo-left-align-background {
						height: auto !important;
						display: flex;
						flex-direction: row;

						@media screen and (width <= $media-md-max) {
							flex-direction: column-reverse;

							>img {
								max-height: 55.8rem !important;
								min-width: unset !important;
								max-width: unset !important;
								width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
}
